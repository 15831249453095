@import "../../../assets/css/scss/variables";

.ut-menulist {
  &__item {
    &__btn {
      margin: 0 -12px -1px 4px;

      svg {
        font-size: 18px;
      }
    }
  }
}
