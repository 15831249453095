/* Animation CSS */
:global {
  @media (min-width: 575px) {
    /* appear - on page load */
    .fade-appear {
      opacity: 0;
      z-index: 1;
    }

    .fade-appear.fade-appear-active {
      opacity: 1;
      transition: opacity 1000ms linear;
    }

    /* enter */
    .fade-enter {
      opacity: 0;
      z-index: 1;
    }

    .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 5000ms linear 5000ms;
    }

    /* exit */
    .fade-exit {
      opacity: 1;
    }

    .fade-exit.fade-exit-active {
      opacity: 0;
      transition: opacity 5000ms linear;
    }

    .fade-exit-done {
      opacity: 0;
    }

    /* appear - on page load */
    .slide-appear {
      background: #fff;
      background: transparent;
      opacity: 0;
      transform: translateY(2%) scale(0.98);
      /* transform-origin: 50% 50%; */
    }

    .slide-appear.slide-appear-active {
      opacity: 1;
      transform: translateY(0) scale(1);
      transition: all 0.3s ease-in-out, opacity 0.15s ease-in-out;
    }

    /* slide enter */
    .slide-enter {
    }

    .slide-enter.slide-enter-active {
    }

    /* slide exit */
    .slide-exit {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    .slide-exit.slide-exit-active {
      opacity: 0;
    }

    .slide-exit-done {
      opacity: 0;
    }
  }

  /* appear - on page load */
  .animstep-appear {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); //$ease-out-cubic;
  }

  .animstep-appear.animstep-appear-active {
    max-height: 1000px;
    transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19); //$ease-in-cubic;
    /*
    @media(max-width: 767px) {
      max-height: 1000px;
    }
    */
  }

  /* animstep enter */
  .animstep-enter {
    max-height: 0;
    overflow: hidden;
  }

  .animstep-enter-active {
    max-height: 1000px;
    transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19); //$ease-in-cubic;
    /*
    @media(max-width: 767px) {
      max-height: 1000px;
    }
    */
  }

  .animstep-enter-done {
    max-height: 1500px;
    /*
    @media(max-width: 767px) {
      max-height: 1000px;
    }
    */
  }

  .animstep-exit {
    max-height: 1000px;
    overflow: hidden;
    /*
    @media(max-width: 767px) {
      max-height: 1000px;
    }
    */
  }

  .animstep-exit-active {
    max-height: 0;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); //$ease-out-cubic;
  }

  .animstep-exit-done {
    // overflow: hidden;
  }

  /* appear - on page load */
  .card-appear {
    margin-top: 4px;
  }

  .card-appear.card-appear-active {
    margin-top: 0;
    transition: all 0.3s ease;
  }

  /* card enter */
  .card-enter {
  }

  .card-enter.card-enter-active {
  }

  /* card exit */
  .card-exit {
  }

  .card-exit.card-exit-active {
  }

  .card-exit-done {
  }

  /* Accordion */
  /*
  .accordion-appear {
    max-height: 0;
    overflow: hidden;
  }
  
  .accordion-appear-active {
    max-height: 500px;
    transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19); //$ease-in-cubic;
  }
  */

  .accordion-enter {
    max-height: 0;
    overflow: hidden;
  }

  .accordion-enter-active {
    max-height: 1000px;
    transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19); //$ease-in-cubic;
  }

  .accordion-enter-done {
    max-height: 1000px;
  }

  .accordion-exit {
    max-height: 1000px;
    overflow: hidden;
  }

  .accordion-exit-active {
    max-height: 0;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); //$ease-out-cubic;
  }

  .accordion-exit-done {
    overflow: hidden;
  }
}
