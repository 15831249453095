// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$white: #ffffff !default;
$lgt-white: #f0f0f0 !default;
$gray-100: #efefef !default;
$gray-200: #e8eaf5 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-450: #ccc !default;
$gray-500: #999999 !default;
$gray-600: #666666 !default;
$gray-700: #495057 !default;
$gray-800: #333333 !default;
$gray-900: #222222 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #0087fa !default; //#0052ac
$lgt-blue: #ceebff !default;
$indigo: #2e378c !default;
$red: #ff2300 !default; //#aa1144
$pink: #e5004d !default;
$purple: #5f2b7f !default;
$orange: #f19b4c !default;
$yellow: #ffc300 !default; //#ffc107
$darkyellow: #ffa800 !default;
$dark-blue: #0037ff;
$lgt-brown: #b3b3b3 !default;

$green: #00af27 !default; //#62ab65
$teal: #20c997 !default;
$cyan: #4ca9e8 !default;
$light-blue: #ceebff !default; //#1199ee
$light-grey: #a5a5a5 !default;
$white-smoke: #f5f5f5 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: $blue !default;
$secondary: $yellow !default;
// $tertiary: $yellow !default;
$success: $green !default;
$info: $cyan !default;
$error: $red !default;
$warning: $orange !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "error": $error,
    "warning": $warning,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$light-gray-bg: #fafbfd !default;
$body-color: $gray-900 !default;

$ut-color-white: $white;
$ut-color-red: $red;
$ut-color-yellow: $yellow;
$ut-color-blue: $blue;
$ut-color-indigo: $indigo;
$ut-color-purple: $purple;
$ut-color-green: $green;
$ut-color-warning: $orange;
$ut-color-error: $error;
$ut-color-link-blue: #0083ff; //#006bed
$ut-color-light-blue: #fafbfd;
$ut-color-darkgray: #d8d8d8;
$ut-color-chipsgray: $gray-100;
$ut-color-light-skyblue: #0087fa;

$text-color-input: $gray-900;
$text-color-gray: $gray-600;
$text-color-disabled: $gray-500;
$text-color-lightgray: $gray-500;

$ut-color-select-hover: #f2f8ff;
$ut-color-select-active: #ceebff;

$ut-color-secondary: $secondary;

// Links
//
// Style anchor elements.
$link-color: $ut-color-link-blue !default;
$link-hover-color: #0037ff; //lighten($link-color, 15%) !default;
$btn-hover-color: lighten(
  $link-color,
  10%
) !default; //lighten($link-color, 10%) !default

// Style p element.
$paragraph-margin-bottom: 1rem !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Poppins", -system-ui, -apple-system,
  BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-auto-filled: system-ui, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Poppins, "Helvetica Neue", Ubuntu, Arial, sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: 20px !default;
$font-size-md-lg: 22px !default;
$font-size-md: 18px !default;
$font-size-sm-1: 15px !default;
$font-size-sm: 14px !default;
$font-size-xsm: 12px !default;
$font-size-xsm-1: 11px !default;
$font-size-xxsm: 10px !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-high: 600 !default;

$line-height-base: 1.5 !default;

$h1-font-size: 52px !default;
$h2-font-size: 44px !default;
$h3-font-size: 34px !default;
$h4-font-size: 24px !default;
$h5-font-size: 18px !default;
$h6-font-size: 14px !default;

$headings-margin-bottom: 1rem / 2 !default;
$headings-font-family: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color-primary: $primary !default;
$headings-color: $gray-900 !default;

/*
$text-size-xlg:               15px !default;
$text-size-lg:                15px !default;
$text-size-md:                15px !default;
$text-size-sm:                15px !default;
$text-size-xsm:               15px !default;

$heading-size-xlg:            15px !default;
$heading-size-lg:             15px !default;
$heading-size-md:             15px !default;
$heading-size-sm:             15px !default;
$heading-size-xsm:            15px !default;
*/

/* Misc Variables */
$header-height-desktop-lg: 95px;
$header-height-desktop: 64px;
$header-height-mbl: 60px;
$btn-height-desktop: 40px;
$btn-height-mbl: 50px;

$mbl-btm-link-margin: 40px;

$mbl-btm-padding: 40px;

$container-padding: 30px;
$container-padding-mbl: 20px;

$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);

// SCSS Variables with JavaScripit || the :export directive for webpack
:export {
  white: $ut-color-white;
  error: $error;
  selectHover: $ut-color-select-hover;
  selectActive: $ut-color-select-active;
  textColorGray: $text-color-gray;
  fontFamily: $font-family-sans-serif;
}
