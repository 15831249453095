@charset "UTF-8";
/*!
  Theme Name: Musafir Jett App
  Theme URI: https://www.musafir.com/
  Description: The Theme for Musafir Jett App
  Author: Musafir
  Version: 2
*/

@import "fonts";
@import "icons";
@import "mtcolors";
@import "animations";
@import "functions";
@import "variables";
//@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "grid";
@import "globalcmp";
