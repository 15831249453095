@import "../../../assets/css/scss/_variables.scss";

$drawer-width: 800px;
$drawer-max-width: 800px;

/* Left drawer */
.ut-drawer {
  &__holder {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
    transition: z-index 1s;

    &--active {
      z-index: 1200;
      transition: none;
    }

    &__actions {
      display: none;
      z-index: -1;
      transition: z-index 1s;

      @media (max-width: 575px) {
        position: fixed;
        display: flex;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 99;
        transition: none;
        padding: 10px;

        button {
          border-radius: 4px;
          box-shadow: 2px 2px 2px 0 rgba(221, 221, 221, 0.5),
            0 15px 10px 10px #fff;
        }
      }
    }
  }
}

.ut-drawer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 95;
  transition: all 0.4s;

  &__header {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    height: $header-height-desktop;
    background: $ut-color-blue;

    &--public {
      background: #19e;
    }

    @media (max-width: 767px) {
      overflow: hidden;
    }

    @media (max-width: 575px) {
      padding: 12px 20px;
      height: $header-height-mbl;
    }

    &__close-btn {
      margin: 0 0 0 -16px !important;
      width: 48px;
      height: 48px;
      cursor: pointer !important;

      svg {
        color: $ut-color-white;
      }
    }

    &__cross {
      display: none !important;

      @media (min-width: 576px) {
        display: block !important;
      }
    }

    &__back {
      display: none !important;

      @media (max-width: 575px) {
        display: block !important;
      }
    }

    h4 {
      flex: 1;
      margin: 0;
      padding: 0 16px 0 8px;
      font-size: $font-size-lg;
      font-weight: $font-weight-normal;
      color: $ut-color-white;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;

      span:last-child {
        button {
          margin: 0;
        }
      }
    }

    &__menu {
      margin: 0 -16px 0 8px;

      svg {
        color: $ut-color-white;
      }
    }
  }

  &__cnt {
    height: calc(100% - #{$header-height-desktop});

    @media (max-width: 575px) {
      height: calc(100% - #{$header-height-mbl});
    }

    &__main {
      padding: 24px;
      max-width: $drawer-max-width;

      @media (max-width: 575px) {
        padding: 20px 20px 160px;
      }

      &__actions {
        display: block;

        @media (max-width: 575px) {
          display: none;
        }

        button {
          margin: 0 0 0 16px;

          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }

  &__outline {
    position: absolute;
    top: 0;
    right: 0;
    width: $drawer-width;
    max-width: $drawer-max-width;
    height: 100vh;
    background: $light-gray-bg;
    transform: translateX(100%);
    transition: all 0.4s;

    @media (max-width: 991px) {
      width: 100vw;
      transform: translateX(100vw);
    }

    .ut-drawer--open & {
      transform: translateX(0);
      transition: all 0.4s;
    }

    .ut-drawer--background & {
      transform: translateX(-200px);
      transition: all 0.4s, width 0.8s;
    }
  }

  &:before {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.65);
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: all 0.2s $ease-out-cubic;

    @media (max-width: 767px) {
      left: 0;
      width: 100%;
    }
  }

  &--open {
    transition: all 0.4s;

    &:before {
      opacity: 1;
      visibility: visible;
      transition: all 0.2s $ease-in-cubic;
    }
  }
}
