@import "../../../../assets/css/scss/_variables.scss";

.ut-linear-progressbar {
  position: fixed;
  width: 100%;
  top: 64px;
  z-index: 1200;

  @media (max-width: 575px) {
    top: 60px;
  }
}
