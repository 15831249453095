@import "../../../assets/css/scss/_variables.scss";

/* Left drawer */
.ut-drawer {
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: -1;

  @media (max-width: 767px) {
    left: auto;
    right: 0;
  }

  &__header {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    height: $header-height-desktop;
    background: $ut-color-blue;

    &--public {
      background: #19e;
    }

    @media (max-width: 575px) {
      height: $header-height-mbl;
    }

    &__close-btn {
      margin: 0 8px 0 -16px !important;
      width: 48px;
      height: 48px;
      cursor: pointer;

      svg {
        color: $ut-color-white;
      }
    }

    &__cnt {
      flex: 1;
      display: flex;
      align-items: center;

      h4 {
        flex: 1;
        margin: 0;
        font-size: $font-size-lg;
        font-weight: $font-weight-normal;
        color: $ut-color-white;
      }

      &__apply-btn {
        min-width: 75px;

        & > span {
          display: block;
        }

        button {
          @media (max-width: 575px) {
            margin: 0 0 0 4px;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }
    }

    &__menu {
      margin: 0 -16px 0 8px;

      svg {
        color: $ut-color-white;
      }
    }
  }

  &__cnt {
    height: calc(100% - #{$header-height-desktop});

    @media (max-width: 575px) {
      height: calc(100% - #{$header-height-mbl});
    }

    &__main {
      padding: 24px;

      @media (max-width: 575px) {
        padding: 20px;
      }
    }
  }

  &__outline {
    position: absolute;
    width: 348px;
    height: 100vh;
    background: $ut-color-white;
    z-index: 95;
    transform: translateX(-348px);
    transition: all 0.3s;

    @media (max-width: 767px) {
      transform: translateX(0);
    }

    @media (max-width: 575px) {
      width: 320px;
      height: 100vh;
    }

    .ut-drawer--open & {
      transform: translateX(0);
      transition: all 0.3s, z-index 0s;

      @media (max-width: 767px) {
        transform: translateX(-348px);
      }

      @media (max-width: 575px) {
        transform: translateX(-320px);
      }
    }
  }

  &:after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.65);
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: all 0.2s $ease-out-cubic;

    @media (max-width: 767px) {
      left: 0;
      width: 100%;
    }
  }

  &--open {
    transition: all 0.3s, z-index 0s;
    z-index: 1200;

    &:after {
      opacity: 1;
      visibility: visible;
      transition: all 0.2s $ease-in-cubic;
    }
  }
}
