@import "../../../assets/css/scss/_variables.scss";

.ut-notification {
  &__outline {
    position: fixed;
    top: 80px;
    right: 32px;
    z-index: 99999;

    @media (max-width: 767px) {
      top: auto;
      bottom: 8px;
      right: auto;
      width: 100%;
      text-align: center;
    }
  }
}
