@import "./variables";

@font-face {
  font-family: "ut-icon";
  src: url("../../fonts/ut-icon.eot?nw2gl5");
  src: url("../../fonts/ut-icon.eot?nw2gl5#iefix") format("embedded-opentype"),
    url("../../fonts/ut-icon.ttf?nw2gl5") format("truetype"),
    url("../../fonts/ut-icon.woff?nw2gl5") format("woff"),
    url("../../fonts/ut-icon.svg?nw2gl5#ut-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ut-icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global {
  .icon-approvals:before {
    content: "\e900";
  }
  .icon-billing:before {
    content: "\e901";
  }
  .icon-console:before {
    content: "\e902";
  }
  .icon-dashboard:before {
    content: "\e903";
  }
  .icon-signout:before {
    content: "\e914";
  }
  .icon-account:before {
    content: "\e90e";
  }
  .icon-organizations:before {
    content: "\e906";
  }
  .icon-policies:before {
    content: "\e907";
  }
  .icon-pricing:before {
    content: "\e908";
  }
  .icon-reports:before {
    content: "\e909";
  }
  .icon-setup:before {
    content: "\e90a";
  }
  .icon-offers:before {
    content: "\e911";
  }
  .icon-defaultuser:before {
    content: "\e90f";
  }
  .icon-trips:before {
    content: "\e90d";
  }
  .icon-offlineRequests:before {
    content: "\e912";
  }

  .icon-expense:before {
    content: "\e910";
  }

  .icon-visa:before {
    content: "\e915";
  }

  .icon-quotation:before {
    content: "\e913";
  }
}
