@import "../../../assets/css/scss/_variables.scss";

.ut-sort {
  &__icon {
    margin: 0 -3px 0 auto;
    padding: 0 0 0 6px;

    svg {
      color: $text-color-gray;
    }
  }
}
