@import "../../../assets/css/scss/_variables.scss";

.ut-notification {
  position: relative;
  margin: 0 0 6px;
  padding: 16px;
  width: 300px;
  max-height: 500px;
  word-wrap: break-word;
  border-radius: 4px;
  // box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 2px 0 rgba(221, 221, 221, 0.5);
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: default;
  opacity: 1;
  animation: toastEnter 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (max-width: 767px) {
    display: inline-block;
    margin: 0;
    width: auto;
    text-align: left;
    max-width: calc(100% - 40px);
    // color: $ut-color-white;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1);
    animation: toastEnterMobile 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &__inactive {
    // max-height: 0;
    // padding: 0;
    transform: translate3d(400px, 0, 0);
    animation: toastOut 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    // transition: max-height 0.25s ease 0.45s, padding 0.25s ease 0.45s;

    @media (max-width: 767px) {
      transform: translate3d(0, 80px, 0);
      animation: toastOutMobile 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  &__item {
    // transition: all 0.3s;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__offline {
    text-align: center;
  }

  &__succees {
    background-color: $success;
  }

  &__warning {
    background-color: $warning;
  }

  &__info {
    background-color: $info;
  }

  &__error {
    background-color: $error;
  }

  &__default {
    background-color: #323232;
  }

  @media (max-width: 767px) {
    //background: #323232;
  }

  &__title {
    margin: 0 0 4px;
    font-size: 16px;
    color: $ut-color-white;
  }

  &__message {
    margin: 0;
    font-size: $font-size-sm;
    color: $ut-color-white;

    a {
      color: $ut-color-white;
    }
  }
}

@keyframes toastEnter {
  from {
    transform: translate3d(400px, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes toastEnterMobile {
  from {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes toastOut {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(400px, 0, 0);
    opacity: 0;
  }
}

@keyframes toastOutMobile {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  }
}
