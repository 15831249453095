@import "../../../assets/css/scss/_variables.scss";
header {
  .ut-header {
    &__icon-btn {
      display: none;
      cursor: pointer;
      padding: 0 12px;
      @media (max-width: 767px) {
        display: block;
        position: fixed;
        top: 8px;
        left: 12px;
        z-index: 99999;
      }

      @media (max-width: 575px) {
        top: 6px;
        left: 8px;
      }

      &__desktop {
        @media (min-width: 768px) {
          display: block !important;
          margin: 0 -32px 0 24px !important;
        }
      }
    }

    &__menu-btn {
      display: none;
      cursor: pointer;
      top: 18px;
      left: 8px;

      @media (max-width: 767px) {
        display: inline-flex;
      }
    }
  }
}
.ut-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 24px;
  width: 100%;
  height: $header-height-desktop;
  z-index: 1100;
  // transform: translate3d(0, 0, 0);

  @media (max-width: 767px) {
    display: block;
  }

  @media (max-width: 575px) {
    padding: 0 $container-padding-mbl;
    height: $header-height-mbl;
    transform: translate3d(0, 0, 0);
  }

  &--musafir-header {
    background: #19e;
  }

  &--business-header {
    background-color: $ut-color-blue;
  }

  &--unauth {
    position: absolute;
    padding: 0 $container-padding;
    z-index: 9999;

    @media (max-width: 575px) {
      position: fixed;
      display: flex;
      padding: 0 $container-padding-mbl;
      height: $header-height-mbl;
      background-color: $ut-color-blue;
      transform: translate3d(0, 0, 0);
    }
  }

  svg {
    color: $ut-color-white;
  }

  &--hide {
    display: none;
  }
}
