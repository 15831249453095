/* Global Components */
:global {
  /* global css for drawer components */

  .pdfview {
    position: relative;

    #printDrawerContent {
      padding: 0;
    }

    #ApprovalChat,
    .hide-on-pdf {
      display: none;
    }

    &::before {
      content: "";
      position: fixed;
      top: 64px;
      left: 0;
      width: 100%;
      height: calc(100% - 64px);
      background: rgba(255, 255, 255, 0.5);
      z-index: 99999;
    }
  }

  .drawer-open {
    height: 100%;
    overflow: hidden;

    body {
      height: 100%;
      overflow: hidden;
    }
  }

  .no-indicator.MuiTabs-indicator {
    background-color: transparent;
  }

  /* Material-ui framework components */
  @media (min-width: 600px) {
    body .MuiMenuItem-root {
      min-height: 48px;
    }

    /* custom scrollbar for menu */
    .MuiPopover-paper {
      -webkit-overflow-scrolling: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 12px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        border: 2px solid #ffffff;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ffffff;
      }

      /* firefox scrollbar */
      scrollbar-color: rgba(0, 0, 0, 0.2) #ffffff;
      scrollbar-width: thin;
    }
  }

  /* ---- Tooltip ---- */
  /* Bottom - Default */
  [data-tooltip],
  [data-tooltip-top] {
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      bottom: auto;
      margin-top: 2px;
      left: 50%;
      opacity: 0;
      z-index: -1;
      visibility: hidden;
      transition: margin 0.25s, opacity 0.25s;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    &:before {
      position: absolute;
      left: 50%;
      top: calc(100% - 10px);
      content: " ";
      margin-left: -5px;
      height: 0;
      width: 0;
      border: solid transparent;
      border-color: transparent;
      border-bottom-color: #222;
      border-width: 5px;
      pointer-events: none;
    }

    &::after {
      top: 100%;
      content: attr(data-tooltip);
      padding: 4px 12px;
      font-size: 12px;
      color: $ut-color-white;
      white-space: nowrap;
      background: #222;
      border-radius: 4px;
      transform: translateX(-50%);
    }

    @media (min-width: 1024px) {
      &:hover {
        &:before,
        &::after {
          margin-top: 0;
          opacity: 1;
          z-index: 9999;
          visibility: visible;
          transition: margin-top 0.25s, opacity 0.25s;
        }
      }
    }
  }

  [data-tooltip-top] {
    &:before {
      top: auto;
      margin-top: 0;
      margin-bottom: 2px;
      bottom: calc(100% - 6px);
      border: solid transparent;
      border-color: transparent;
      border-top-color: #222;
      transition: margin-bottom 0.25s, opacity 0.25s;
    }

    &:after {
      content: attr(data-tooltip-top);
      top: auto;
      margin-top: 0;
      margin-bottom: 2px;
      bottom: 100%;
      transition: margin-bottom 0.25s, opacity 0.25s;
    }

    @media (min-width: 1024px) {
      &:hover {
        &:before,
        &::after {
          margin-bottom: 0;
          transition: margin-bottom 0.25s, opacity 0.25s;
        }
      }
    }
  }

  /* ---- Custom grid ---- */
  textarea {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Firstname title - Mr/Mrs */
  [class~="col-title"] {
    margin-right: -10px;
    max-width: 100px;
    width: 100px;

    @media (max-width: 767px) {
      margin-right: 0;
      flex: 0 0 50%;
      max-width: 50%;
      width: 50%;
    }
  }

  /* Firstname */
  [class~="col-first-name"] {
    flex: 1;

    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  [class~="col-md-4"] {
    @media (min-width: 1200px) {
      &:nth-of-type(3n) {
        [class~="autosuggest-list"] {
          left: auto;
          right: 0;
        }
      }
    }

    @media (max-width: 1199px) {
      flex: 0 0 50%;
      max-width: 50%;

      &:nth-of-type(2n) {
        [class~="autosuggest-list"] {
          left: auto;
          right: 0;
        }
      }
    }

    @media (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  [class~="col-md-8"] {
    @media (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  [class~="col-xs-12"] {
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  /* Map: The popup bubble styling. */
  .popup-bubble {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    /* Style the bubble. */
    font-weight: 500;
    padding: 5px;
    color: #222;
    max-height: 60px;
    background-color: #eee;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    transform: translate(-50%, -100%);
  }

  /* The parent of the bubble. A zero-height div at the top of the tip. */
  .popup-bubble-anchor {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;
  }

  /* This element draws the tip. */
  .popup-bubble-anchor::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    // border-top: 8px solid #004499;
    border-top: 8px solid #eee;
  }

  /* The parent of the bubble. A zero-height div at the top of the tip. */
  .popup-container.active {
    z-index: 1 !important;
  }

  .popup-container.active .popup-bubble {
    background: #0087fa;
    color: white;
  }

  /* This element draws the tip. */
  .popup-container.active .popup-bubble-anchor::after {
    border-top: 8px solid #0087fa;
  }

  /* JavaScript will position this div at the bottom of the popup tip. */
  .popup-container {
    cursor: pointer;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
    font-family: "Poppins" Arial sans-serif;
  }

  .gm-style .gm-style-iw-t::after {
    content: none;
  }

  /* Rich text editor */
  .ck-file-dialog-button {
    display: none;
  }
  .ck-editor__main {
    margin-bottom: 0px;
  }

  .ck-sticky-panel {
    .ck.ck-dropdown {
      display: none;
    }

    .ck-dropdown.ck-heading-dropdown,
    .ck-dropdown.ck-toolbar-dropdown {
      display: block;
    }
  }

  .ut-ck--error {
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border-color: #ff2300;
    }
  }

  .ut-ck--view-mode {
    .ck-sticky-panel {
      display: none;
    }

    .ck.ck-editor__editable_inline {
      padding: 0px;
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border: none;
    }

    .ck.ck-editor__editable_inline > :first-child {
      margin-top: 0px;
    }

    .ck.ck-editor__editable_inline > :last-child {
      margin-bottom: 2px;
    }
  }

  .ck.ck-editor {
    /*
    p {
      // padding-top: 2px;
    }
    */

    h2,
    h3,
    h4 {
      margin-bottom: 10px;
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
    }

    h3 {
      font-size: 20px;
      font-weight: 400;
    }

    h4 {
      font-size: 18px;
      font-weight: 400;
    }

    b,
    strong {
      font-weight: 500;
    }

    .ck-content blockquote {
      margin-top: 16px;
      padding-top: 6px;
      padding-bottom: 6px;

      p:last-child {
        margin: 0;
      }
    }
  }

  div[class*="MuiSlider-colorPrimary"] {
    color: #0087fa !important;
  }
}
