/* Material colors Set - mtcolors.scss */

:global {
  .mt-red {
    background: #ef5350;
  }

  .mt-pink {
    background: #ec407a;
  }

  .mt-purple {
    background: #ab47bc;
  }

  .mt-deep-purple {
    background: #7e57c2;
  }

  .mt-indigo {
    background: #5c6bc0;
  }

  .mt-blue {
    background: #42a5f5;
  }

  .mt-light-blue {
    background: #29b6f6;
  }

  .mt-cyan {
    background: #26c6da;
  }

  .mt-teal {
    background: #26a69a;
  }

  .mt-green {
    background: #66bb6a;
  }

  .mt-light-green {
    background: #9ccc65;
  }

  .mt-lime {
    background: #c0ca33; //600
  }

  .mt-yellow {
    background: #fbc02d; //700
  }

  .mt-amber {
    background: #ffb300; //700
  }

  .mt-orange {
    background: #ffa726;
  }

  .mt-deep-orange {
    background: #ff7043;
  }
}
