@import "../../../assets/css/scss/_variables.scss";

/* Navigation */
.ut-navigation {
  position: fixed;
  top: $header-height-desktop;
  left: 0;
  height: calc(100vh - #{$header-height-desktop});
  font-size: $font-size-sm;
  color: $gray-600;
  background-color: $ut-color-white;
  border-right: solid 1px #e9ecef;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 999;
  width: 70px;

  &:hover {
    @media (min-width: 768px) {
      width: 280px;
      white-space: nowrap;
      //box-shadow: 0 1px 8px -1px rgba(0,0,0,0.2);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

      .ut-navigation__list__item__text,
      .ut-navigation__list__item__submenu__arrow {
        display: block;
        opacity: 1;
      }

      ul ul {
        height: auto;
      }
    }
  }

  &__outline {
    position: relative;

    &:after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.65);
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      transition: all 0.2s $ease-out-cubic;
    }
    &--hide {
      display: none;
    }
  }

  &__submenu {
    position: relative;
    max-height: 0;
    overflow: hidden;
    // transition: all 0.5s ease-out;
    transition: all 0.5s $ease-out-cubic;

    &--active {
      max-height: 500px;
      // transition: all 0.5s ease-in;
      transition: all 0.5s $ease-in-cubic;
    }
  }

  .ut-navigation__list__item__text,
  .ut-navigation__list__item__submenu__arrow {
    display: none;
    opacity: 0;
  }

  ul ul {
    height: 0;
  }

  &__list {
    position: relative;
    margin: 0;
    padding: 12px 0 50px;
    width: 279px;
    list-style: none;

    @media (max-width: 767px) {
      padding: 12px 0 110px;
    }

    &__item {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: relative;
        padding: 8px 20px;
        color: $gray-600;
        cursor: pointer;

        &:hover {
          color: $ut-color-blue;
        }
      }

      &__icon {
        min-width: 30px;

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 30px;
          height: 30px;
          font-size: $font-size-lg;
          border-radius: 50%;
          vertical-align: middle;

          &::before {
            z-index: 2;
          }
        }
      }

      &__text {
        flex: 1;
        margin-left: 15px;
      }

      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        min-height: 30px;
      }
    }

    ul {
      margin: 0;
      padding: 0 0 0 45px;
      list-style: none;
    }
  }

  &__list__item--selected {
    a {
      color: $ut-color-blue;
    }

    i {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #daebfd;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }

  &__list__item__submenu {
    &__arrow {
      position: absolute;
      right: 20px;
      top: 50%;
      margin: 1px 0 0;
      width: 20px;
      height: 20px;
      fill: $gray-600;
      transform: translateY(-50%);
      transition: all 0.3s;
      opacity: 0.65;
    }

    &--active {
      .ut-navigation__list__item__submenu__arrow {
        transform: translateY(-50%) rotate(180deg);
        transition: all 0.3s;
      }
    }
  }

  @media (max-width: 767px) {
    top: $header-height-desktop;
    width: 280px;
    white-space: nowrap;
    overflow: visible;
    transform: translateX(-280px);
    transition: all 0.3s;

    .ut-navigation__list__item__text,
    .ut-navigation__list__item__submenu__arrow {
      display: block;
      opacity: 1;
    }

    ul ul {
      height: auto;
    }

    ul {
      position: relative;
      background: $ut-color-white;
      z-index: 90;
    }
  }

  @media (max-width: 575px) {
    top: $header-height-mbl;
    height: calc(100vh - #{$header-height-mbl});
  }

  &--active {
    .ut-navigation {
      @media (max-width: 767px) {
        transform: translateX(0);
        transition: all 0.3s;
        box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
      }
    }

    &:after {
      @media (max-width: 767px) {
        opacity: 1;
        visibility: visible;
        z-index: 90;
        transition: all 0.2s $ease-in-cubic;
      }
    }
  }
}
