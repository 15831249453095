@import "../../../assets/css/scss/variables";

.ut-logo {
  &__musafir-logo {
    width: 178px;
    height: 40px;
    font-size: 0;
    align-self: flex-end;
    background: url("../../../assets/images/svg/business-logo-yellow.svg")
      no-repeat center;
    background-size: 100% auto;
    cursor: default;

    &.ut-logo__color-white {
      align-self: center;
      margin-left: -9px;
      width: 140px;
      min-width: 39px;
      height: 34px;
      background: url("../../../assets/images/svg/business-logo-yellow-small.svg")
        no-repeat center;
      background-size: 100% auto;
      cursor: pointer;
    }

    @media (max-width: 575px) {
      display: none;
      align-self: center;
      width: 148px;
      height: 36px;
      background: url("../../../assets/images/svg/business-logo-yellow-mobile.svg")
        no-repeat center;
      background-size: 100% auto;
      cursor: pointer;
    }
  }

  &__musafir-svg-logo {
    align-self: center;
    flex: 0 0 140px;
    width: 140px;
    height: 35px;
    background: url("../../../assets/images/svg/musafir.svg") no-repeat center;
    background-size: 100% auto;
    text-indent: -9999px;

    @media (max-width: 575px) {
      display: none;
    }
  }

  &__show-on-mbl {
    @media (max-width: 575px) {
      display: inline-flex;
    }
  }

  &__hide-on-tab {
    @media (max-width: 767px) {
      display: none;
    }
  }
}
